//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    ProductCard: () => import('@/containers/theme/ProductCard'),
  },
  props: {
    themeSaleProduct: {
      type: [Object, Array],
      default: () => {},
    },
    backgroundImage: {
      type: String,
      default: null,
    },
    wFull: {
      type: Boolean,
      default: false,
    },
    coloredProductTitle: {
      type: String,
      default: null,
    },
  },
}
