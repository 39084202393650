var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"max-w-1700 mx-auto 2xl:px-8",style:(_vm.backgroundImage
        ? ("background-image: url(" + _vm.backgroundImage + "); \n      padding: 40px 2.5vw;\n\n    ")
        : '')},[_c('div',{staticClass:"flex justify-around text-center first-products px-2"},_vm._l((_vm.themeSaleProduct),function(product,index){return _c('div',{key:index,staticClass:"px-2 lg:mx-3 tsm:px-0 mt-10 sm:w-400",class:_vm.wFull ? 'w-11/12' : 'w-1/2'},[_c('ProductCard',{attrs:{"w":2400,"height":840,"image":product.media,"title":product.title,"price":product.price,"undiscounted-price":product.undiscountedPrice,"collection-price":product.collectionPrice
              ? product.collectionPrice[_vm.$i18n.localeProperties.currencyCode]
              : null,"collection-undiscounted-price":product.collectionUndiscountedPrice
              ? product.collectionUndiscountedPrice[
                  _vm.$i18n.localeProperties.currencyCode
                ]
              : null,"show-undiscounted-price":product.showUndiscountedPrice,"to":product.to,"badge":product.badge,"custom-badge":product.customBadge,"custom-badge-image":product.customBadgeImage,"custom-badge-text":product.customBadgeText,"custom-badge-class":product.customBadgeClass,"custom-badge-style":product.customBadgeStyle,"is-from":product.isFrom,"is-image-border":product.isImageBorder,"colored-product-title":_vm.coloredProductTitle,"lazy":"","grow-on-hover":"","is-full-height":""}})],1)}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }